import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import aboutA from "./components/about-a"; // import aboutB from "./components/about-b";

import aboutC from "./components/about-c";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "RFID技术系统解决方案供应商_深圳常达智能",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "关于公司,常达"
    }, {
      name: "description",
      content: "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。"
    }]
  },
  data: function data() {
    return {
      list: [// {
      //   image: require("@/assets/wash/swiper/1.jpg"),
      //   text: "标签缝制",
      //   imgOffset: "-30%",
      //   icon: require("@/assets/wash/swiper2/icon1.png"),
      //   icon1: require("@/assets/wash/swiper2/icon1c.png"),
      // },
      {
        image: require("@/assets/about/swiper2/1.jpg"),
        text: "智能包装",
        imgOffset: "-45%",
        icon: require("@/assets/about/swiper2/icon1.png"),
        icon1: require("@/assets/about/swiper2/icon1c.png")
      }, {
        image: require("@/assets/about/swiper2/2.jpg"),
        text: "服装门店",
        imgOffset: "-60%",
        icon: require("@/assets/about/swiper2/icon2.png"),
        icon1: require("@/assets/about/swiper2/icon2c.png")
      }, {
        image: require("@/assets/about/swiper2/3.jpg"),
        text: "固定资产",
        imgOffset: "-38%",
        icon: require("@/assets/about/swiper2/icon3.png"),
        icon1: require("@/assets/about/swiper2/icon3c.png")
      }, {
        image: require("@/assets/about/swiper2/4.jpg"),
        text: "防伪溯源",
        imgOffset: "-23%",
        icon: require("@/assets/about/swiper2/icon4.png"),
        icon1: require("@/assets/about/swiper2/icon4c.png")
      }, {
        image: require("@/assets/about/swiper2/5.jpg"),
        text: "仓储管理",
        imgOffset: "-10%",
        icon: require("@/assets/about/swiper2/icon5.png"),
        icon1: require("@/assets/about/swiper2/icon5c.png")
      }, {
        image: require("@/assets/about/swiper2/6.jpg"),
        text: "智能洗涤",
        imgOffset: "-44%",
        icon: require("@/assets/about/swiper2/icon6.png"),
        icon1: require("@/assets/about/swiper2/icon6c.png")
      }],
      recommend: {
        title: "我们的荣誉",
        list: [{
          image: require("@/assets/about/recommend/0.png")
        }, {
          image: require("@/assets/about/recommend/1.png")
        }, {
          image: require("@/assets/about/recommend/8.png")
        }, {
          image: require("@/assets/about/recommend/9.png")
        }, {
          image: require("@/assets/about/recommend/10.png")
        }, {
          image: require("@/assets/about/recommend/11.png")
        }, {
          image: require("@/assets/about/recommend/2.png")
        }, {
          image: require("@/assets/about/recommend/3.png")
        }, {
          image: require("@/assets/about/recommend/4.png")
        }, {
          image: require("@/assets/about/recommend/5.png")
        }, {
          image: require("@/assets/about/recommend/6.png")
        }, {
          image: require("@/assets/about/recommend/7.png")
        } // { image: require("@/assets/about/recommend/12.png") },
        // { image: require("@/assets/about/recommend/13.png") },
        // { image: require("@/assets/about/recommend/14.png") },
        // { image: require("@/assets/about/recommend/15.png") },
        // { image: require("@/assets/about/recommend/16.png") },
        // { image: require("@/assets/about/recommend/17.png") },
        // { image: require("@/assets/about/recommend/18.png") },
        // { image: require("@/assets/about/recommend/19.png") },
        // { image: require("@/assets/about/recommend/20.png") },
        // { image: require("@/assets/about/recommend/21.png") },
        ]
      },
      recommend2: {
        title: "我们的专利",
        list: [// { image: require("@/assets/about/recommend/1.png") },
        // { image: require("@/assets/about/recommend/2.png") },
        // { image: require("@/assets/about/recommend/3.png") },
        // { image: require("@/assets/about/recommend/4.png") },
        // { image: require("@/assets/about/recommend/5.png") },
        // { image: require("@/assets/about/recommend/6.png") },
        // { image: require("@/assets/about/recommend/7.png") },
        // { image: require("@/assets/about/recommend/8.png") },
        // { image: require("@/assets/about/recommend/9.png") },
        // { image: require("@/assets/about/recommend/10.png") },
        // { image: require("@/assets/about/recommend/11.png") },
        {
          image: require("@/assets/about/recommend/12.png")
        }, {
          image: require("@/assets/about/recommend/13.png")
        }, {
          image: require("@/assets/about/recommend/14.png")
        }, {
          image: require("@/assets/about/recommend/15.png")
        }, {
          image: require("@/assets/about/recommend/16.png")
        }, {
          image: require("@/assets/about/recommend/17.png")
        }, {
          image: require("@/assets/about/recommend/18.png")
        }, {
          image: require("@/assets/about/recommend/19.png")
        }, {
          image: require("@/assets/about/recommend/20.png")
        }, {
          image: require("@/assets/about/recommend/21.png")
        }],
        containerStyle: {
          background: "none"
        }
      },
      recommend1: {
        title: "我们的软件著作权登记证书",
        list: [{
          image: require("@/assets/about/recommend1/1.png")
        }, {
          image: require("@/assets/about/recommend1/2.png")
        }, {
          image: require("@/assets/about/recommend1/3.png")
        }, {
          image: require("@/assets/about/recommend1/4.png")
        }, {
          image: require("@/assets/about/recommend1/5.png")
        }, {
          image: require("@/assets/about/recommend1/6.png")
        }, {
          image: require("@/assets/about/recommend1/7.png")
        }],
        containerStyle: {
          background: "none"
        }
      }
    };
  },
  components: {
    top: top,
    aboutA: aboutA,
    // aboutB,
    aboutC: aboutC
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite", "setheaderTopBtnWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    this.setheaderTopBtnWhite(true);
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
    this.setheaderTopBtnWhite(false);
  }
};